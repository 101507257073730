import React, { useState ,useEffect} from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebaseConfig/firebaseconfig";
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, Button } from 'antd';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';

const AddPropertyForm = () => {
  const [loading, setLoading] = useState(false);
  const [featured, setFeatured] = useState(true);
  const [formValues, setFormValues] = useState({
  coverImgUrl: null,
  photos: []
  });
  const [packageName,setPackageName]=useState('');
  const [packagePrice,setPackagePrice]=useState(null);
  const [selectedDates, setSelectedDates]=useState([]);
  const [disabledDays, setDisabledDays]=useState([]);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });
  const [selectedServiceNames, setSelectedServiceNames] = useState([]);
const services = [
  {"icon": "faWifi", "enName": "WiFi", "arName": "واي فاي", "deName": "WLAN"},
  {"icon": "faTv", "enName": "TV", "arName": "تلفاز", "deName": "Fernseher"},
  {"icon": "faUtensils", "enName": "Kitchen", "arName": "مطبخ", "deName": "Küche"},
  {"icon": "faBowlFood", "enName": "Dishwasher", "arName": "غسالة صحون", "deName": "Geschirrspüler"},
  {"icon": "faParking", "enName": "Free Parking", "arName": "موقف سيارات مجاني", "deName": "Kostenloses Parken"},
  {"icon": "faParking", "enName": "Paid Parking", "arName": "موقف سيارات مدفوع", "deName": "Bezahltes Parken"},
  {"icon": "faSnowflake", "enName": "Air Conditioning", "arName": "تكييف هواء", "deName": "Klimaanlage"},
  {"icon": "faUserTie", "enName": "Private Workspace", "arName": "مساحة عمل خاصة", "deName": "Privater Arbeitsplatz"},
  {"icon": "faWaterLadder", "enName": "Pool", "arName": "بركة", "deName": "Schwimmbad"},
  {"icon": "faHotTub", "enName": "Hot Tub", "arName": "حوض استحمام ساخن", "deName": "Whirlpool"},
  {"icon": "faUmbrella", "enName": "Patio", "arName": "فناء", "deName": "Patio"},
  {"icon": "faFire", "enName": "Grill", "arName": "شواية", "deName": "Grill"},
  {"icon": "faTable", "enName": "Outdoor Dining Area", "arName": "منطقة تناول الطعام الخارجية", "deName": "Außenbereich"},
  {"icon": "faFire", "enName": "Fire Pit", "arName": "موقد نار", "deName": "Feuerstelle"},
  {"icon": "faBilliards", "enName": "Billiards Table", "arName": "طاولة بلياردو", "deName": "Billardtisch"},
  {"icon": "faFireplace", "enName": "Indoor Fireplace", "arName": "مدفأة داخلية", "deName": "Innenkamin"},
  {"icon": "faMusic", "enName": "Piano", "arName": "بيانو", "deName": "Klavier"},
  {"icon": "faDumbbell", "enName": "Exercise Equipment", "arName": "معدات رياضية", "deName": "Sportgeräte"},
  {"icon": "faWater", "enName": "Lake Access", "arName": "وصول إلى البحيرة", "deName": "Zugang zum See"},
  {"icon": "faUmbrellaBeach", "enName": "Beach Access", "arName": "وصول إلى الشاطئ", "deName": "Zugang zum Strand"},
  {"icon": "faSwimmer","enName": "Private Pool", "arName": "حمام سباحة خاص", "deName": "Privater Pool"}
  
];

  
  
  const handleFinish = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    values.coverImgUrl = formValues.coverImgUrl;
    values.photos = formValues.photos;
    const servicesObjects=constructServicesObjects();

    try {
      const { coverImgUrl, photos, propertyState } = values;
     
      const isFeatured = values.propertyState === "featured";
      if(servicesObjects.length===0){
        message.error("please selecte at least one service");
        setLoading(false);
        return;
      }else if (values.propertyType==="sale" && selectedDates.length!==0){
        message.error("packages are only allowed in rent property");
        setLoading(false);
        return;
      }
      const { coverUrl, photoUrlList } = await uploadImages(coverImgUrl, photos);
      const propertyData = {
        propertyType: values.propertyType,
        propertyName: values.propertyName,
        price: values.price,
        location: values.location,
        compound: values.compound,
        numBeds: values.numBeds,
        numShower: values.numShower,
        coverImgUrl: coverUrl,
        imagesUrl: photoUrlList,
        view: values.view,
        description: values.description,
        descriptionAR: values.descriptionAR,
        descriptionGER: values.descriptionGER,
        featured: isFeatured,
        services:servicesObjects
      };
   
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${baseUrl}/property/addProperty`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(propertyData)
      });
      const responseData = await response.json();
      if(responseData){
        if(responseData.err==='please provide property images'){
      
        message.error("Please upload property images");
        }
        else{

          if(selectedDates.length>0){

            savePackages(responseData.newProperty._id);
          }
          message.success("Property added successfully");
          setLoading(false);
          window.location.reload();
        }
     
      }
    



    } catch (error) {
      message.error('error in adding property ');
      console.error("Error adding property:", error);
    }
    setLoading(false);
  
  };
 
  const uploadImages = async (coverImgUrl, photos) => {
    let photoUrlList = [];
    try {
      const coverRef = ref(storage, `coverImages/${coverImgUrl.name}`);
      await uploadBytes(coverRef, coverImgUrl);
      const coverUrl = await getDownloadURL(coverRef);
  

      const uploadPromises = photos.map(async (photo) => {
        const photoRef = ref(storage, `photos/${photo.name}`);
        await uploadBytes(photoRef, photo);
        return getDownloadURL(photoRef); 
      });
  

      photoUrlList = await Promise.all(uploadPromises);
  
      return { coverUrl, photoUrlList };
    } catch (err) {
      console.error("Error uploading image to Firebase:", err);
      throw err; // Re-throw the error for further handling
    }
  };
  

  

  const handleCoverImageChange = (file) => {
    setFormValues(prevValues => ({ ...prevValues, coverImgUrl: file }));
  };
  const handleCoverImageRemove = () => {
    setFormValues(prevValues => ({ ...prevValues, coverImgUrl: null }));
  };


  const handlePhotosChange = ({ fileList }) => {

    setFormValues(prevValues => ({
      ...prevValues,
      photos: fileList.map(file => file.originFileObj)
    }));
  };
  const handleRemovePhoto = (file) => {
    setFormValues(prevValues => ({
      ...prevValues,
      photos: prevValues.photos.filter(photo => photo !== file.originFileObj)
    }));
  };

  const convertToLocalizedDate = (dateObj) => {
    const { day, month, year } = dateObj;
    // const date = new Date(year, month - 1, day+1); 
    const date = new Date(Date.UTC(year, month - 1, day));
   return  date.toISOString();
  };

  const addPackage=()=>{
  if(packageName===null||packageName===''){
    message.error('please provide a package name');
  }
  else if(packagePrice===null||packagePrice===''){
    message.error('please provide a package price');
  }
  else if(new Date(convertToLocalizedDate(selectedDayRange.from))<=Date.now()){
    message.error('please provide a valid date');
  }

  else{
    setSelectedDates((prevDates) => [...prevDates, { from: selectedDayRange.from, to: selectedDayRange.to,packageName: packageName,packagePrice:packagePrice}]);
    setSelectedDayRange({
       from: null,
      to: null});
    setPackageName('');
    setPackagePrice('');

  }
  }

  const savePackages = async (propId)=>{

    try{
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const response = await fetch(`${baseUrl}/package/addPackages`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      selectedDates,
      propId
    })
  });
}catch(err){
  console.log("error in saving Packages : "+err);
}
  }


  const removePackage = (date) => {
    setSelectedDates((prevDates)=>prevDates.filter(d=>d!==date))
    
  };


  const constructDisabledDates = (stDate, enDates) => {
    let disabledDates = [];
  
    const addDatesInRange = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      

      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        disabledDates.push({
          year: date.getFullYear(),
          month: date.getMonth() + 1, 
          day: date.getDate(),
        });
      }
    };
  
 
    for (let i = 0; i < stDate.length; i++) {
      addDatesInRange(stDate[i], enDates[i]);
    }
  

    setDisabledDays(disabledDates);
  };
  useEffect(()=>{
    let startDates=[];
    let endDates=[];
    for (let i =0 ;i<selectedDates.length;i++){
      const from = selectedDates[i].from; 
      const to = selectedDates[i].to;
      const startDate = new Date(Date.UTC(from.year, from.month - 1, from.day));
      const endDate = new Date(Date.UTC(to.year, to.month - 1, to.day));
      startDates.push(startDate);
      endDates.push(endDate);
    
    }
    constructDisabledDates(startDates, endDates);
   
  },[selectedDates])
  const handleCheckboxChange = (service) => {
   
    setSelectedServiceNames((prev) => 
      prev.includes(service.enName) 
        ? prev.filter((s) => s !== service.enName) 
        : [...prev, service.enName] 
    );
  };

  const constructServicesObjects=()=>{
    let serviceObjects =[];
    for (let i =0 ;i<services.length;i++){
      for (let j =0 ;j<selectedServiceNames.length;j++){
        if(services[i].enName===selectedServiceNames[j]){
          serviceObjects.push(services[i]);
        }
      }
    }
  return serviceObjects;

  };
  return (
    <div className="flex flex-col items-center justify-center my-5 px-4">
      <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-center mb-4">Add Property</h1>
        <form  onSubmit={handleFinish} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label htmlFor="propertyName" className="text-sm font-medium mb-2">Property Name</label>
              <input
                id="propertyName"
                name="propertyName"
                type="text"
                placeholder="Enter property name"
                className="p-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="compound" className="text-sm font-medium mb-2">Compound</label>
              <input
                id="compound"
                name="compound"
                type="text"
                placeholder="Enter compound name"
                className="p-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="location" className="text-sm font-medium mb-2">Location</label>
              <input
                id="location"
                name="location"
                type="text"
                placeholder="Enter location"
                className="p-2 border border-gray-300 rounded"
                required
              />
            </div>

        

            <div className="flex flex-col">
              <label htmlFor="numBeds" className="text-sm font-medium mb-2">Number of Bedrooms</label>
              <input
                id="numBeds"
                name="numBeds"
                type="number"
                placeholder="Enter number of beds"
                className="p-2 border border-gray-300 rounded"
                min="1"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="numShower" className="text-sm font-medium mb-2">Number of Bathrooms</label>
              <input
                id="numShower"
                name="numShower"
                type="number"
                placeholder="Enter number of showers"
                className="p-2 border border-gray-300 rounded"
                min="1"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="propertyType" className="text-sm font-medium mb-2">Property Type</label>
              <select
                id="propertyType"
                name="propertyType"
                className="p-2 border border-gray-300 rounded"
                required
              >
                <option value="">Select property type</option>
                <option value="rent">Rent</option>
                <option value="sale">Sale</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="view" className="text-sm font-medium mb-2">View</label>
              <select
                id="view"
                name="view"
                className="p-2 border border-gray-300 rounded"
                required
              >
                <option value="">Select view</option>
                <option value="sea">Sea</option>
                <option value="garden">Garden</option>
                <option value="pool">Pool</option>
                <option value="city">City</option>
                <option value="marina">Marina</option>
              </select>
            </div>

            <div className="flex flex-col">
              <label htmlFor="price" className="text-sm font-medium mb-2">Price</label>
              <input
                id="price"
                name="price"
                type="number"
                placeholder="Enter price"
                className="p-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="description" className="text-sm font-medium mb-2">English Description</label>
              <textarea
                id="description"
                name="description"
                placeholder="Enter English description"
                className="p-2 border border-gray-300 rounded"
                required
              />
            </div>
            

            <div className="flex flex-col">
              <label htmlFor="propertyState" className="text-sm font-medium mb-2">Property State</label>
              <select
                id="propertyState"
                name="propertyState"
                className="p-2 border border-gray-300 rounded"
                required
              >
                <option value="featured">Featured</option>
                <option value="notFeatured">Not Featured</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="descriptionAR" className="text-sm font-medium mb-2">Arabic Description</label>
              <textarea
                id="descriptionAR"
                name="descriptionAR"
                placeholder="Enter Arabic description"
                className="p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="descriptionGER" className="text-sm font-medium mb-2">German Description</label>
              <textarea
                id="descriptionGER"
                name="descriptionGER"
                placeholder="Enter German description"
                className="p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="flex flex-col">
            <label htmlFor="coverImgUrl" className="text-sm font-medium mb-2">Cover Image</label>
            <Upload
              name="coverImgUrl"
              listType="picture"
              maxCount={1}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              beforeUpload={(file) => {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                  message.error('You can only upload JPG/PNG file!');
                }
                return isJpgOrPng;
              }}
              customRequest={({ file, onSuccess }) => {
                handleCoverImageChange(file);
                onSuccess();
              }}
              onRemove={handleCoverImageRemove}
            >
              <Button icon={<UploadOutlined />}>Upload Cover Image</Button>
            </Upload>
            
          </div>
         
          </div>

         

          <div className="flex flex-col items-center">
  <label htmlFor="photos" className="text-sm font-medium mb-2 text-center">Photos</label>
  <Upload
    className="flex justify-center flex-wrap"
    name="photos"
    listType="picture-card"
    multiple
    showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
    style={{ width: '100%', maxWidth: '20em' }} // Adjust width for responsiveness
    beforeUpload={(file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG files!');
      }
      return isJpgOrPng;
    }}
    onChange={handlePhotosChange}
    onRemove={handleRemovePhoto}
  >
    <Button icon={<UploadOutlined />} style={{ textAlign: 'center' }}>
      Upload Photos
    </Button>
  </Upload>
</div>

          <div>
          <div
  className="form-group d-flex flex-column align-items-center"
  style={{
    border: '1px solid black',
    borderRadius: '25px',
    padding: '5px',
    marginBottom: '20px', 
  }}
>
  <h4 className="text-center" style={{ marginBottom: '15px' ,marginTop:"10px"}}>
    What We Offer ?
  </h4>
  <div className="d-flex justify-content-center flex-wrap">
      {services.map((service, index) => (
        <div
          className="form-check"
          key={index} 
          style={{
            flex: '0 1 180px',
            border: '1px solid black',
            borderRadius: '20px',
            transition: 'background-color 0.3s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px',
            padding: '10px',
            backgroundColor: '#f8f9fa',
            overflow: 'hidden',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9ecef')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f8f9fa')}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`service-${index}`} 
            checked={selectedServiceNames.includes(service.enName)} // Check if the enName is in selectedServices
            onChange={() => handleCheckboxChange(service)} 
            style={{ marginLeft: '2px', marginRight: '2px' }}
          />
          <label className="form-check-label" htmlFor={`service-${index}`}>
            {service.enName} 
          </label>
        </div>
      ))}
    </div>

</div>



          <hr className="mx-auto mt-5 mb-5" style={{ width: "50%" }} />
          <h4 className="text-center pb-2">Add Packages</h4>

<div className="d-flex flex-wrap justify-content-around">
<div class="packResTable relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Package Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Price
                </th>
                <th scope="col" class="px-6 py-3">
                    From
                </th>
                <th scope="col" class="px-6 py-3">
                    To
                </th>
                <th scope="col" class="px-6 py-3">
                    Action
                </th>
            </tr>
        </thead>
        <tbody>
        {selectedDates.map((date, index) => (
      <tr key={index} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                 {date.packageName}
                </th>
                <td>{`${date.packagePrice} EGP`}</td>
        <td>{`${date.from.day}/${date.from.month}/${date.from.year}`}</td>
        <td>{`${date.to.day}/${date.to.month}/${date.to.year}`}</td>
        <td class="px-6 py-4">
                    <button class="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={()=>removePackage(date)}>Remove</button> </td>
      </tr>
    ))}
        </tbody>
    </table>
 
</div>
{/* <div className='d-flex mt-5 justify-content-around'> */}
<div style={{ transform: 'scale(0.8)', transformOrigin: 'top left' }}>
  <Calendar
    value={selectedDayRange}
    onChange={setSelectedDayRange}
    disabledDays={disabledDays}
    shouldHighlightWeekends
    renderFooter={() => (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0.5rem 1rem' }}>
        <input
          type="text"
          value={packageName} 
          onChange={(e) => setPackageName(e.target.value)}
          placeholder="Enter Package Name"
          style={{
            marginBottom: '0.5rem',
            padding: '0.25rem',
            borderRadius: '0.3rem',
            border: '1px solid #ccc',
            fontSize: '0.9rem',
          }}
        />
        <input
          type="number"
          value={packagePrice} 
          onChange={(e) => setPackagePrice(e.target.value)}
          placeholder="Enter Package Price"
          style={{
            marginBottom: '0.5rem',
            padding: '0.25rem',
            borderRadius: '0.3rem',
            border: '1px solid #ccc',
            fontSize: '0.9rem',
          }}
        />
        <button
          type="button"
          onClick={addPackage}
          style={{
            color: 'white',
            backgroundColor: '#0076FF',
            borderRadius: '0.3rem',
            padding: '0.5rem 1rem',
            fontSize: '0.9rem',
          }}
        >
          Add Package
        </button>
      </div>
    )}
  />
</div>

</div>

</div>

          <div className="flex justify-center">
         
            <button
              type="submit"
              className="px-6 py-2 bg-black text-white rounded hover:bg-gray-800 transition"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Add Property'}
            </button>
          </div>

       
          
        </form>


      </div>
    </div>
  );
};

export default AddPropertyForm;
